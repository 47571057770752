import { keyBy } from "lodash"
import { computed, Ref } from "@vue/composition-api"
import { Mission as MissionType } from "@shared/enums"

import useStore from "./useStore"

import { Buzz } from "@/types/buzz"
import { Mission } from "@/types/mission"

export default function useFamilyFeud(
  mission: Ref<Mission>,
  buzz: Ref<Buzz[]>
) {
  const { store } = useStore()

  const viewerID = computed(() => store.getters["auth/user"].id)
  const plays = computed(() => store.getters.missionPlaysArray)
  const users = computed(() => store.getters["GameUsers/users"])
  const allowInput = computed(
    () =>
      !!store.getters.gameStatus?.familyFeud?.allowFamilyFeudCategoryTextInput
  )

  const isFamilyFeud = computed(
    () => mission.value.behavior === MissionType.FamilyFeud
  )

  const pendingBuzz = computed(() => {
    const playsKeyedByUserID = keyBy(plays.value, "userID") || {}

    const isPendingBuzz = (buzz: Buzz) => {
      const play = playsKeyedByUserID[buzz.userID]
      if (!play) return true
      return Math.round(buzz.timestamp / 1000) > play.time
    }

    return buzz.value.filter(isPendingBuzz)
  })

  const isViewerTurn = computed(
    () =>
      pendingBuzz.value.findIndex(buzz => buzz.userID === viewerID.value) === 0
  )

  const currentUser = computed(() => {
    const userID = pendingBuzz.value[0]?.userID
    return users.value?.[userID]
  })

  return {
    isViewerTurn,
    currentUser,
    allowInput,
    isFamilyFeud
  }
}
