var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "survey-says-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c("OpacityTransition", [
        _vm.wrongAnswerVisibility
          ? _c(
              "div",
              { staticClass: "survey-says-mission__wrong-answer-notif" },
              _vm._l(_vm.wrongAnswerNumber, function (n) {
                return _c("SvgIcon", {
                  key: n,
                  attrs: { name: "wrong", color: "danger", width: "33%" },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
      _c(
        "SlideDownTransition",
        { attrs: { maxHeight: "160px" } },
        [
          _vm.isHost || !_vm.isPaused
            ? _c("ResizableText", {
                staticClass: "survey-says-mission__title",
                class: { "survey-says-mission__title--paused": _vm.isPaused },
                attrs: { message: _vm.mission.instructions },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "OpacityTransition",
        [
          !_vm.isExplain
            ? _c("OptionsList", {
                attrs: {
                  options: _vm.options,
                  show: "",
                  columns: "",
                  autoWidth: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (ref) {
                        var option = ref.option
                        return [
                          _c(
                            "FlipVerticalTransition",
                            [
                              option.played
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "ChoiceBtn",
                                                  _vm._g(
                                                    {
                                                      style:
                                                        !_vm.enlargedUI &&
                                                        option.display.length >
                                                          13 &&
                                                        "font-size: 75%",
                                                      attrs: {
                                                        size:
                                                          !_vm.enlargedUI &&
                                                          "sm",
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(option.display) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_vm._v(" " + _vm._s(option.display) + " ")]
                                  )
                                : _c(
                                    "ChoiceBtn",
                                    {
                                      attrs: {
                                        size: !_vm.enlargedUI && "sm",
                                        secondary: true,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submit(
                                            option.display,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isHost ? option.display : "?"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  606881102
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "SlideDownTransition",
        { attrs: { maxHeight: "160px" } },
        [
          _vm.isInputAvailable
            ? _c(
                "v-flex",
                { staticClass: "w-full", attrs: { shrink: "" } },
                [
                  _c("TextField", {
                    staticClass: "mission-text-feild",
                    attrs: {
                      allowFreeSubmit: "",
                      placeholder: _vm.placeholder,
                      status: _vm.status,
                      show: "show",
                      buttonText: "SUBMIT",
                    },
                    on: { onSubmit: _vm.submit },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isHost
        ? _c(
            "div",
            { attrs: { column: "" } },
            [
              _vm.teamTurnText
                ? _c("div", [_vm._v(_vm._s(_vm.teamTurnText))])
                : _vm._e(),
              _c(
                "RtbButton",
                {
                  staticClass: "mx-1 my-1",
                  attrs: {
                    size: "sm",
                    outline: "",
                    disabled: !_vm.buzz.length,
                  },
                  on: { click: _vm.resetBuzzer },
                },
                [_vm._v(" Reset Buzzer ")]
              ),
              _c(
                "RtbButton",
                {
                  staticClass: "mx-1 my-1",
                  attrs: { size: "sm", outline: "" },
                  on: { click: _vm.handleWrongAnswer },
                },
                _vm._l(_vm.exCount, function (n) {
                  return _c("span", { key: n }, [_vm._v(" X ")])
                }),
                0
              ),
              _c(
                "RtbButton",
                {
                  staticClass: "mx-1 my-1",
                  attrs: { size: "sm", outline: "" },
                  on: { click: _vm.resetWrongAnswer },
                },
                [_vm._v(" Reset X ")]
              ),
              _c(
                "RtbButton",
                {
                  staticClass: "mx-1 my-1",
                  attrs: { size: "sm", outline: "" },
                  on: { click: _vm.toggleTextInput },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.allowFamilyFeudInput
                          ? "Disable Text Input"
                          : "Enable Text Input"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }