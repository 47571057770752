<template>
  <v-layout column justify-center fill-height class="survey-says-mission">
    <OpacityTransition>
      <div
        v-if="wrongAnswerVisibility"
        class="survey-says-mission__wrong-answer-notif"
      >
        <SvgIcon
          v-for="n in wrongAnswerNumber"
          :key="n"
          name="wrong"
          color="danger"
          width="33%"
        />
      </div>
    </OpacityTransition>

    <SlideDownTransition maxHeight="160px">
      <ResizableText
        v-if="isHost || !isPaused"
        class="survey-says-mission__title"
        :class="{ 'survey-says-mission__title--paused': isPaused }"
        :message="mission.instructions"
      />
    </SlideDownTransition>

    <OpacityTransition>
      <OptionsList v-if="!isExplain" :options="options" show columns autoWidth>
        <template #option="{ option }">
          <FlipVerticalTransition>
            <v-tooltip bottom v-if="option.played">
              <template v-slot:activator="{ on }">
                <ChoiceBtn
                  v-on="on"
                  :size="!enlargedUI && 'sm'"
                  :style="
                    !enlargedUI &&
                    option.display.length > 13 &&
                    'font-size: 75%'
                  "
                >
                  {{ option.display }}
                </ChoiceBtn>
              </template>
              {{ option.display }}
            </v-tooltip>

            <ChoiceBtn
              v-else
              :size="!enlargedUI && 'sm'"
              :secondary="true"
              @click="submit(option.display, true)"
            >
              {{ isHost ? option.display : "?" }}
            </ChoiceBtn>
          </FlipVerticalTransition>
        </template>
      </OptionsList>
    </OpacityTransition>

    <SlideDownTransition maxHeight="160px">
      <v-flex shrink class="w-full" v-if="isInputAvailable">
        <TextField
          allowFreeSubmit
          class="mission-text-feild"
          :placeholder="placeholder"
          :status="status"
          :show="'show'"
          :buttonText="'SUBMIT'"
          @onSubmit="submit"
        />
      </v-flex>
    </SlideDownTransition>

    <div column v-if="isHost">
      <div v-if="teamTurnText">{{ teamTurnText }}</div>

      <RtbButton
        class="mx-1 my-1"
        size="sm"
        outline
        @click="resetBuzzer"
        :disabled="!buzz.length"
      >
        Reset Buzzer
      </RtbButton>
      <RtbButton size="sm" outline class="mx-1 my-1" @click="handleWrongAnswer">
        <span v-for="n in exCount" :key="n"> X </span>
      </RtbButton>
      <RtbButton size="sm" outline class="mx-1 my-1" @click="resetWrongAnswer">
        Reset X
      </RtbButton>
      <RtbButton size="sm" outline class="mx-1 my-1" @click="toggleTextInput">
        {{ allowFamilyFeudInput ? "Disable Text Input" : "Enable Text Input" }}
      </RtbButton>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { toRefs } from "@vue/composition-api"

import Sfx from "@shared/Sfx"
import { GameMixin } from "@/mixins"
import { RtbButton } from "@/components/ui"
import { db } from "@/firebase"
import { Mode } from "@shared/enums"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import OpacityTransition from "@/components/GroupTeams/Common/GameModes/OpacityTransition.vue"
import SlideDownTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideDownTransition"
import FlipVerticalTransition from "@/components/GroupTeams/Common/Games/GameCardParts/FlipVerticalTransition"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList"

import User from "@shared/User"
import useFamilyFeud from "@/use/useFamilyFeud"
import { serialize } from "@/helpers"

import TextField from "./TextField.vue"

export default {
  name: "SurveySays",
  mixins: [GameMixin],
  components: {
    OptionsList,
    ChoiceBtn,
    FlipVerticalTransition,
    SlideDownTransition,
    OpacityTransition,
    ResizableText,
    TextField,
    RtbButton
  },
  props: {
    mode: String,
    mission: Object,
    buzz: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { mission, buzz } = toRefs(props)
    const {
      isViewerTurn,
      isFamilyFeud,
      currentUser: currentFamilyFeudUser,
      allowInput: allowFamilyFeudInput
    } = useFamilyFeud(mission, buzz)
    return {
      isViewerTurn,
      currentFamilyFeudUser,
      allowFamilyFeudInput,
      isFamilyFeud
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isAudit", "user"]),
    ...mapGetters(["missionPlaysArray", "isScribe", "gameID", "orgID"]),
    ...mapGetters("GameUsers", ["users"]),
    isPaused() {
      return this.$store.getters.gameStatus?.paused !== true
    },
    isViewerPresenter() {
      return this.$store.getters["group/isViewerPresenter"]
    },
    enlargedUI() {
      return (
        this.$store.getters.gameStatus?.enlargedUI && this.isViewerPresenter
      )
    },
    isInputAvailable() {
      if (this.isViewerPresenter) return false

      if (this.isHost) return false

      return this.allowFamilyFeudInput && this.isViewerTurn
    },
    given() {
      return this.missionPlaysArray.reduce((acc, val) => {
        if (typeof val?.answer === "string") {
          acc.push(val.answer)
        } else if (Array.isArray(val?.answer)) {
          val.answer.forEach(answer => {
            if (typeof answer === "string") acc.push(answer)
          })
        }
        return acc
      }, [])
    },
    normalized() {
      return this.given.map(serialize)
    },
    normalizedWithOptions() {
      const options = this.optionsWithDisplay
      return this.normalized.reduce((acc, val) => {
        const found = options.find(({ options }) => options.includes(val))
        return found ? [...acc, ...found.options] : [...acc, val]
      }, [])
    },

    options() {
      const given = this.normalizedWithOptions

      return this.optionsWithDisplay.map(option => ({
        display: option.display,
        played: option.options.some(string => given.includes(string))
      }))
    },
    teamID() {
      return this.$store.getters["group/canViewerSelectGlobalTeam"] &&
        !User.isPresenter(this.user)
        ? this.$store.getters["group/globalTeamID"]
        : this.$store.getters.teamID
    },
    optionsWithDisplay() {
      const answer = String(this.mission?.answer)
      let array

      if (answer.indexOf(";") > -1) {
        array = answer.split(";")
      } else if (answer.indexOf(",") > -1) {
        array = answer.split(",")
      } else {
        array = [answer]
      }

      return array.map(string => {
        const options = String(string).split(",")
        return {
          display: String(options[0]).trim(),
          options: options.map(serialize)
        }
      })
    },
    optionsText() {
      return this.optionsWithDisplay.map(({ display }) => display).join(", ")
    },
    status() {
      return !this.isExplain ? "active" : ""
    },
    optionsClasses() {
      return {
        show: this.mode === Mode.Play
      }
    },
    isExplain() {
      return this.mode === Mode.Explain
    },
    isPlayTime() {
      return (
        this.mode === Mode.Play ||
        this.mode === Mode.Huddle ||
        this.mode === Mode.Social
      )
    },
    placeholder() {
      if (this.isHost) {
        return ""
      } else if (!this.isExplain) {
        return "Enter here..."
      } else if (!this.isScribe) {
        return "Waiting for scribe..."
      } else {
        return ""
      }
    },
    teams() {
      return this.$store.getters.chats
    },
    teamTurnText() {
      const user = this.currentFamilyFeudUser
      if (!user) return ""
      const team = this.teams?.[user.teamID]
      if (!team) return ""
      return `User Turn: ${user.firstname} ${user.lastname} (${team.name})`
    },
    wrongAnswerVisibility() {
      return this.$store.getters.gameStatus?.familyFeud?.wrongAnswer?.visibility
    },
    wrongAnswerNumber() {
      return this.$store.getters.gameStatus?.familyFeud?.wrongAnswer?.number
    },
    exCount() {
      return (this.wrongAnswerNumber + 1) % 4 || 1
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameSoundEffect"]),

    async submit(data, admin) {
      if (admin && !this.isHost) return 0

      if (!data) return 0

      const normalized = serialize(data)

      if (this.normalizedWithOptions.includes(normalized)) return 0

      this.answer = normalized

      if (this.isHost) {
        if (!this.mission.disableActionSfx)
          this.updateGameSoundEffect(Sfx.TILE_TURN)
        await this.checkAnswer({
          user: this.currentFamilyFeudUser
        })
      }

      await this.checkAnswer()
      this.answer = ""
    },
    resetBuzzer() {
      db.auxiliary()
        .ref(`org/${this.orgID}/game/${this.gameID}/buzz/${this.mission?.id}`)
        .set(null)
    },
    toggleTextInput() {
      this.$store.dispatch("updateGameStatusAny", {
        gameID: this.gameID,
        "familyFeud/allowFamilyFeudCategoryTextInput":
          !this.allowFamilyFeudInput
      })
    },
    resetWrongAnswer() {
      this.$store.dispatch("updateGameStatusAny", {
        gameID: this.gameID,
        "familyFeud/wrongAnswer": {}
      })
    },
    handleWrongAnswer() {
      const number = (this.wrongAnswerNumber + 1) % 4 || 1

      this.$store.dispatch("updateGameStatusAny", {
        gameID: this.gameID,
        "familyFeud/wrongAnswer": { visibility: true, number }
      })

      if (!this.mission.disableActionSfx) this.updateGameSoundEffect(Sfx.STRIKE)

      setTimeout(() => {
        this.$store.dispatch("updateGameStatusAny", {
          gameID: this.gameID,
          "familyFeud/wrongAnswer/visibility": false
        })
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
.survey-says-mission {
  display: flex;
  align-items: center;
  justify-content: center;

  &__title {
    min-height: 26px;
    transition: 1s;
    flex: 1;

    &--paused {
      transform: rotateX(360deg);
      color: $color-grey;
    }
  }

  &__instructions {
    min-height: 32px;
  }

  &__title {
    margin-top: min(max(4px, 1vh), 16px);
  }

  &__wrong-answer-notif {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    z-index: 4;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.3) 40%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .mission-text-feild {
    position: relative;
    padding: {
      right: 48px;
      left: 48px;
    }
    margin: {
      top: 24px;
      bottom: 24px;

      top: min(max(5px, 1vh), 24px);
      bottom: min(max(5px, 1vh), 24px);
    }
    transform: translateY(10px);

    visibility: hidden;

    opacity: 0;
    transition: all 0.25s ease-out;

    .orientation-portrait & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .resizable-text-container--scrollbar {
    overflow: hidden;
  }

  .mission-text-feild.show {
    visibility: visible;
    transform: none;
    opacity: 1;
  }
  .text-input {
    pointer-events: none;
    input {
      margin-top: 0 !important;
      caret-color: $color-black !important;
    }
    .v-input__slot {
      margin: 0;
      min-height: auto;
      border-radius: 10px !important;
      border: 2px solid $color-grey-light3 !important;
    }
    .v-text-field__details {
      display: none;
    }
  }
  .text-input.active {
    pointer-events: auto;
  }
  .text-input.wrong ::-webkit-input-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong ::-moz-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong :-moz-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.wrong :-ms-input-placeholder {
    color: $color-red !important;
    text-decoration: line-through;
  }
  .text-input.correct {
    pointer-events: none;
    .v-input__slot {
      border: 2px solid $correct_color !important;
    }
  }
  .text-input-submit {
    padding: 5px;
    font-weight: bold;
    color: $color-grey-light3;
    opacity: 1;
    pointer-events: none;
    cursor: not-allowed;
    transition: color 0.3s;
  }
  .text-input-submit:hover {
    filter: brightness(1.2);
  }
  .text-input-submit.active {
    text-decoration: none;
    color: $primary_accent_color;
    pointer-events: auto;
    cursor: pointer;
  }
  .text-input-submit.correct {
    color: $correct_color;
    pointer-events: none;
    cursor: not-allowed;
  }
  .mission-instructions {
    margin-top: 21px;
    padding: 5px 15px;
  }
}
</style>
